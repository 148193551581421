import React from 'react'

const StatusPendaftaranAcceptedComponent = () => {
  return (
    <div className='w-28 h-8 bg-[#34D399] rounded-full flex justify-center items-center'>
        <p>Accepted</p>
    </div>
  )
}

export default StatusPendaftaranAcceptedComponent