import React from 'react'

const StatusPendaftaranRejectedComponent = () => {
  return (
    <div className='w-28 h-8 bg-[#FB7185] rounded-full flex justify-center items-center'>
        <p>Rejected</p>
    </div>
  )
}

export default StatusPendaftaranRejectedComponent