import React from 'react'

const StatusPendaftaranReviewComponent = () => {
  return (
    <div className='w-28 h-8 bg-[#C1C1C1] rounded-full flex justify-center items-center'>
        <p>Review</p>
    </div>
  )
}

export default StatusPendaftaranReviewComponent